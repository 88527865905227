import React from "react"
import { Container } from "reactstrap"
import Navbar from "./Navbar"
import Navbar2 from "./Navbar2"
import styled from "styled-components"
import { MediaQueries } from "../utils/responsive"

const StyledHeader = styled.header`
  @media ${MediaQueries.mobileUp} {
    /* height: 100px; */
  }
`

const Header = () => {
  return (
    <StyledHeader>
      <Container fluid className="mx-0 px-0 d-flex">
        <Navbar />
        <Navbar2 topNav={true} />
      </Container>
    </StyledHeader>
  )
}

Header.propTypes = {}

Header.defaultProps = {}

export default Header
