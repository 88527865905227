import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import Header from "./header"
import GlobalStyle from "../styles/globalStyles"
import Footer from "../components/Footer"
import CookieConsent from "react-cookie-consent"
import theme from "../styles/theme"
import { DimensionsProvider } from "../utils/dimensionsProvider"
import "./bootstrap.min.css"
import "./layout.css"

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation("common")

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <DimensionsProvider>
        <Header />
        <main style={{ width: "100%" }}>{children}</main>
      </DimensionsProvider>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText={t("cookieAccept")}
        declineButtonText={t("cookieRefuse")}
        cookieName="gatsby-gdpr-google-analytics"
      >
        {`${t("cookieNotice")} `}
        <Link to="/en/privacy" alt="Privacy policy">
          {`${t("cookieMoreinfo")} `}
        </Link>
      </CookieConsent>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
