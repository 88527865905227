const theme = {
  colors: {
    red: "rgb(165, 15, 15)",
    green: "rgb(53, 117, 124)",
    greenOp: "rgba(53, 117, 124, .8)",
    purple: "rgb(76, 68, 122)",
    lightgray: "rgb(188, 179, 187)",
    yellow: "rgb(221, 213, 172)",
    lightblue: "rgb(135, 177, 178)",
    darkgray: "rgb(53, 53, 53)",
    white: "rgb(255, 255, 255)",
    whiteOp: "rgba(255, 255, 255, .6)",
    darkgrayOp: "rgb(53, 53, 53, .8)",
    purpleOp: "rgb(76, 68, 122, .8)",
    black: "rgb(35,35,35)",
    footerGreen: "rgb(135,177,178)",
    footerYellow: "rgb(221,213,172)",
    footerGray: "rgb(188,179,187)",
  },
}
export default theme
