import React from "react"
import styled from "styled-components"

const Button = styled.button`
    z-index: 99;
    cursor: pointer;
    width: 100px;
    width: 33vw;
    background: ${props => props.theme.colors.yellow};
    transition: all 200ms ease-in-out;
    color: ${props => props.theme.colors.darkgray};
    border: 1px solid transparent;
    border-bottom: ${props => `2px solid ${props.theme.colors.lightgray}`};
    height: 100%;
    font-size: 1.6rem;
    &:hover {
        border-bottom: ${props => `2px solid ${props.theme.colors.lightgray}`};
        color: ${props => props.theme.colors.darkgray};
    }
    &:focus {
        outline: none;
    }
`

// Docs --> https://www.framer.com/api/motion/component/
const MenuToggle = ({ toggle, isOpen }) => (
    <Button onClick={toggle}>MENU</Button>
)

export default MenuToggle
