export const DeviceSize = {
  mobile: 576,
  tablet: 768,
  laptop: 992,
  desktop: 1200,
}

export const MediaQueries = {
  mobileUp: `all and (min-width: ${DeviceSize.mobile}px)`,
  tabletUp: `all and (min-width: ${DeviceSize.tablet}px)`,
  laptopUp: `all and (min-width: ${DeviceSize.laptop}px)`,
  desktopUp: `all and (min-width: ${DeviceSize.desktop}px)`,
}
