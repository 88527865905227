import React, { useState } from "react"
import { useTranslation } from "react-i18next"
// import { AlternateLinksContext } from "../wrapWithI18nProvider"
import {
  NavLinkContainer,
  NavMenu,
  NavAnchorLink,
  NavLink,
} from "./NavbarElements"
import MenuToggle from "./menuToggle"

const MobileNavLinks = () => {
  const { t, i18n } = useTranslation("common")
  const language = i18n.language
  const [isOpen, setOpen] = useState(false)

  //   TODO - to re-add News, just add 1 in the [2,3,4].map
  return (
    <NavLinkContainer>
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      {isOpen && (
        <NavMenu mobileNav={true} className={isOpen && "activeMenu"}>
          <li className="item">
            <NavLink
              invert={true}
              to={`/${language}`}
              onClick={() => setOpen(false)}
            >
              Main
            </NavLink>
          </li>
          {[2, 3, 4].map(i => (
            <li className="item" key={Math.random().toString() + i}>
              <NavLink
                to={`/${language}/${t(`menu.${i + 4}.slug`)}`}
                activeClassName={"active"}
                className={"my-link"}
                onClick={() => setOpen(false)}
              >
                {t(`menu.${i + 4}.name`)}
              </NavLink>
            </li>
          ))}
          <li className="item">
            <NavAnchorLink
              invert={true}
              to={`/${language}/${t(`menu.4.slug`)}`}
              onClick={() => setOpen(false)}
            >
              {t(`menu.4.name`)}
            </NavAnchorLink>
          </li>
        </NavMenu>
      )}
    </NavLinkContainer>
  )
}

export default MobileNavLinks
