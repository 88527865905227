import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import theme from "../../styles/theme"
import { isBrowser } from "../../utils/isBrowser"
import { MdEmail, MdPhone } from "react-icons/md"

const getFontColor = (theme, path) => {
    if (path.indexOf("blog") > -1) return `${theme.colors.purple}!important`
    if (path.indexOf("video") > -1) return `${theme.colors.white}!important`
    if (path.indexOf("webshop") > -1)
        return `${theme.colors.darkgray}!important`
    if (path.indexOf("photos") > -1) return `${theme.colors.darkgray}!important`
    return theme.colors.darkgray
}

const StyledContact = styled.div`
    text-align: center;
    h1,
    p {
        color: ${isBrowser && getFontColor(theme, window.location.pathname)};
    }
    .contact-link {
        color: ${props => props.theme.colors.darkgray};
        &:hover {
            color: ${props => props.theme.colors.darkgray};
        }
        svg {
            margin-bottom: -0.5rem;
            font-size: 24px;
            margin-right: 1rem;
        }
    }
`
const Contact = () => {
    const { t, i18n } = useTranslation("home")
    const language = i18n.language
    const data = useStaticQuery(graphql`
        query {
            sanityHomePage {
                contactEmail
                contactPhone
                header: _rawContactHeader
            }
        }
    `)
    return (
        <StyledContact>
            <h1 id={t(`common:menu.${4}.slug`)} style={{ margin: "2rem 0" }}>
                {data.sanityHomePage.header[language]}
            </h1>
            <p>
                <a
                    href="mailto:info@justanatomy.com"
                    alt="email link"
                    className="contact-link"
                >
                    <MdEmail /> {data.sanityHomePage.contactEmail}
                </a>
            </p>
            <p>
                <a
                    href="tel:+36302850030"
                    alt="phone link"
                    className="contact-link"
                >
                    <MdPhone /> {data.sanityHomePage.contactPhone}
                </a>
            </p>
        </StyledContact>
    )
}

export default Contact
