import React from "react"
import Contact from "../Contact"
import { Container, Row, Col } from "reactstrap"
import theme from "../../styles/theme"
import { isBrowser } from "../../utils/isBrowser"

const getBgColor = (theme, path) => {
  if (path.indexOf("blog") > -1) return theme.colors.footerYellow
  if (path.indexOf("video") > -1) return theme.colors.footerGreen
  if (path.indexOf("webshop") > -1) return theme.colors.footerYellow
  if (path.indexOf("photos") > -1) return theme.colors.footerGreen
  return theme.colors.footerGreen
}

const Footer = props => {
  return (
    <Container
      className="footer"
      fluid
      style={{
        background:
          (isBrowser && getBgColor(theme, window.location.pathname)) ||
          theme.colors.footerGreen,
      }}
    >
      <Container className="px-0" id="contact">
        <Row className="justify-content-lg-center">
          <Col xs={12} className="Contact">
            <Contact />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
export default Footer
