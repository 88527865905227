import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { DimensionsContext } from "../../utils/dimensionsProvider"

import {
  Nav,
  NavAnchorLink,
  NavLink,
  NavMenu,
  Navitem,
} from "./NavbarElements2"
import Logo from "./logo"

const Navbar2 = props => {
  const { t, i18n } = useTranslation("common")
  const language = i18n.language
  const [dimensions, setDimensions] = useContext(DimensionsContext)

  //   TODO - to re-add News, just add 1 in the [2,3,4].map
  return (
    <Nav
      fixed={props.fixed}
      topNav={props.topNav}
      dimensions={dimensions}
      className="nav-2"
    >
      <NavMenu narrow={props.narrow}>
        <Navitem i={0}>
          <NavLink to={`/${language}`}>
            <Logo />
          </NavLink>
        </Navitem>
        {[2, 3, 4].map(i => (
          <Navitem i={i} key={Math.random().toString() + i}>
            <NavLink
              invert={i === 2 ? true : false}
              to={`/${language}/${t(`menu.${i + 4}.slug`)}`}
            >
              {t(`menu.${i + 4}.name`)}
            </NavLink>
          </Navitem>
        ))}
        <Navitem i={5}>
          <NavAnchorLink invert={false} to={`/${language}/${t(`menu.4.slug`)}`}>
            {t(`menu.4.name`)}
          </NavAnchorLink>
        </Navitem>
      </NavMenu>
    </Nav>
  )
}

Navbar2.propTypes = {
  topNav: PropTypes.bool,
  narrow: PropTypes.bool,
  fixed: PropTypes.bool,
}

Navbar2.defaultProps = {
  topNav: false,
  narrow: false,
  fixed: false,
}

export default Navbar2
