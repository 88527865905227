import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"
import { MediaQueries } from "../../utils/responsive"
import * as tinycolor2 from "tinycolor2"
// tinycolor docs --> https://github.com/bgrins/TinyColor

const getColor = (theme, i, hover) => {
    if (i === 1)
        return hover
            ? tinycolor2(theme.colors.purple)
                  .darken()
                  .toString()
            : theme.colors.purple
    if (i === 2)
        return hover
            ? tinycolor2(theme.colors.yellow)
                  .darken()
                  .toString()
            : theme.colors.yellow
    if (i === 3)
        return hover
            ? tinycolor2(theme.colors.green)
                  .darken()
                  .toString()
            : theme.colors.green
    if (i === 4)
        return hover
            ? tinycolor2(theme.colors.darkgray)
                  .darken()
                  .toString()
            : theme.colors.darkgray

    if (i === 5)
        return hover
            ? tinycolor2(theme.colors.red)
                  .darken()
                  .toString()
            : theme.colors.red
}

export const Nav = styled.div`
    width: 100px;
    display: none;
    top: 130px;
    left: 15px;
    padding: 0;
    z-index: 98;

    @media ${MediaQueries.mobileUp} {
        position: fixed;
        display: block;
        top: 15px;
        left: 30px;
        width: 100px;
    }
`
export const NavMenu = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: "center";
    margin: 0;
    list-style: none;
    background: transparent;

    @media ${MediaQueries.mobileUp} {
        /* align-items:  "flex-start"};
    justify-content: space-evenly;
    flex-wrap: nowrap; */
        z-index: 1;
    }
`

export const Navitem = styled.li`
    padding: 0;
    order: 3;
    width: 100px;
    height: ${props => (props.i === 0 ? "150px" : "50px")};
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: ${props => (props.i === 0 ? "flex-start" : "center")};
    justify-content: center;
    text-align: center;
    background: ${props => getColor(props.theme, props.i, false)};
    transition: all 200ms ease-in-out;
    &:hover {
        background: ${props => getColor(props.theme, props.i, true)};
    }
    @media ${MediaQueries.mobileUp} {
        margin-bottom: 15px;
        width: 100px;
        height: ${props => (props.i === 0 ? "150px" : "50px")};

        &:hover {
            width: ${props => (props.i === 0 ? "100px" : "150px")};
            margin-bottom: 15px;
        }
    }
`

export const NavLink = styled(Link)`
    display: block;
    padding: 0;
    color: ${props =>
        props.invert ? props.theme.colors.darkgray : props.theme.colors.white};
    text-decoration: none;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 2px;
    width: 100px;
    height: 50px;
    padding-top: 17px;
    &:hover {
        text-decoration: none;
        color: ${props =>
            props.invert
                ? props.theme.colors.darkgrayOp
                : props.theme.colors.lightgray};
    }

    @media ${MediaQueries.mobileUp} {
        max-width: 100px;
    }
`

export const NavAnchorLink = styled(AnchorLink)`
    display: block;
    padding: 0;
    color: ${props =>
        props.invert ? props.theme.colors.darkgray : props.theme.colors.white};
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover {
        text-decoration: none;
        color: ${props =>
            props.invert
                ? props.theme.colors.darkgrayOp
                : props.theme.colors.lightgray};
    }

    @media ${MediaQueries.mobileUp} {
        padding: 15px;
    }
`
