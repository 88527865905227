import React, { useEffect, useState } from "react"
import styled from "styled-components"
import LogoFile from "../../images/svg/logo-inline.svg"
import theme from "../../styles/theme"
import { isBrowser } from "../../utils/isBrowser"

// Dynamically set logo color based on pathname
const getColor = (theme, path) => {
  if (path.indexOf("blog") > -1) return theme.colors.purple
  if (path.indexOf("video") > -1) return theme.colors.red
  if (path.indexOf("webshop") > -1) return theme.colors.green
  if (path.indexOf("photos") > -1) return theme.colors.darkgray
  return theme.colors.red
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .cls-2 {
    fill: ${props => props.logoColor};
  }
`

const LogoImg = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 100000;
`
const LogoMobile = props => {
  const [color, setColor] = useState("rgb(165, 15, 15)")

  useEffect(() => {
    setColor(isBrowser && getColor(theme, window.location.pathname))
  }, [])

  return (
    <LogoWrapper logoColor={color}>
      <LogoImg>
        <LogoFile />
      </LogoImg>
    </LogoWrapper>
  )
}

export default LogoMobile
