import React from "react"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"
import { LeftSection, Nav, RightSection } from "./NavbarElements"
import MobileNavLinks from "./mobileNavLinks"
import { DeviceSize } from "../../utils/responsive"
import LogoMobile from "./logoMobile"
import { NavLink } from "./NavbarElements"

const Navbar = () => {
  const { i18n } = useTranslation("common")
  const language = i18n.language
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile })
  return (
    <Nav>
      <LeftSection>
        {isMobile && (
          <NavLink to={`/${language}/`}>
            <LogoMobile />
          </NavLink>
        )}
      </LeftSection>
      <RightSection>{isMobile && <MobileNavLinks />}</RightSection>
    </Nav>
  )
}

export default Navbar
