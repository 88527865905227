// globalStyles.js
import { createGlobalStyle } from "styled-components"
import "@fontsource/poppins"
import "@fontsource/ibm-plex-sans"
import "@fontsource/ibm-plex-serif"

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
  }
  body {
    margin: 0;
    background: ${props => props.theme.colors.black};
    padding: 0;
  }
  p, li, label {
    font-family: 'IBM Plex Serif';
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'IBM Plex Sans';
    color: ${props => props.theme.colors.white};
  }
  h2 {
    font-size: 28px;
  }
  button, a {
      font-family: 'Poppins';
      color: ${props => props.theme.colors.white};
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  header, main {
    flex: 1 0 100%;
  }
`

export default GlobalStyle
