import React, { useState, useEffect } from "react"
import { isBrowser } from "./isBrowser"

const DimensionsContext = React.createContext([{}, () => {}])

const DimensionsProvider = props => {
    const [dimensions, setDimensions] = useState({
        height: isBrowser ? window.innerHeight : 0,
        width: isBrowser ? window.innerWidth : 0,
        yOffset: isBrowser ? window.pageYOffset : 0
    })

    useEffect(() => {
        function handleScroll() {
            setDimensions({
                height: isBrowser ? window.innerHeight : 0,
                width: isBrowser ? window.innerWidth : 0,
                yOffset: isBrowser ? window.pageYOffset : 0
            })
        }

        if (isBrowser) {
            window.addEventListener("scroll", handleScroll)
        }
        return function cleanupListeners() {
            window.removeEventListener("scroll", handleScroll)
        }
    })
    return (
        <DimensionsContext.Provider value={[dimensions, setDimensions]}>
            {props.children}
        </DimensionsContext.Provider>
    )
}

export { DimensionsContext, DimensionsProvider }
