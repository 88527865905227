import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"
import { MediaQueries } from "../../utils/responsive"

export const Nav = styled.nav`
  flex: 1 0 100%;
  width: 100%;
  height: 35px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: flex;
  align-items: center;
  @media ${MediaQueries.mobileUp} {
    padding: 0 1.5rem;
    height: 60px;
  }
`

export const LeftSection = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
`

export const RightSection = styled.div`
  display: flex;
  height: 100%;
  flex: 2;
  justify-content: flex-end;
  @media ${MediaQueries.mobileUp} {
    display: none;
  }
`

export const NavLinkContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  .activeMenu {
    opacity: 1;
  }
`

export const NavMenu = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  background-color: ${props => props.theme.colors.black};
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 35px;
  left: 0;
  z-index: 99;
  height: 100%;
  opacity: ${props => (props.mobileNav ? 0 : 1)};
  transition: all 200ms ease-in-out;
  @media ${MediaQueries.mobileUp} {
    flex-direction: row;
    background-color: transparent;
    position: relative;
    top: unset;
    left: unset;
    z-index: 1;
  }

  .item {
    height: 50px;
    padding: 0 1.1em;
    color: ${props => props.theme.colors.white};
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all 200ms ease-in-out;
    @media ${MediaQueries.mobileUp} {
      border-bottom: 1px solid ${props => props.theme.colors.white};
      height: 100%;
      border-top: 1px transparent solid;
      &:hover {
        border-top: 1px solid ${props => props.theme.colors.white};
      }
    }
  }
`

export const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
  height: 30px;
  width: 100%;
  color: ${props => props.theme.colors.white};
  font-weight: 500;
  font-size: 14px;
  display: flex;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`

export const NavAnchorLink = styled(AnchorLink)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  padding: 0 1.1em;
  color: ${props => props.theme.colors.white};
  font-weight: 500;
  font-size: 14px;
  display: flex;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`
